import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [startDate, setStartDate] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [resultDate, setResultDate] = useState(null);

  useEffect(() => {
    if (startDate && numberOfDays !== "" && !isNaN(numberOfDays)) {
      // Convert the start date string to a Date object and adjust for timezone.
      const localDate = new Date(Date.parse(startDate + "T00:00:00"));

      // Add the specified number of days.
      localDate.setDate(localDate.getDate() + parseInt(numberOfDays, 10));

      // Set the result date.
      setResultDate(localDate);
    } else {
      setResultDate(null);
    }
  }, [startDate, numberOfDays]);

  const handleDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleDaysChange = (event) => {
    setNumberOfDays(event.target.value);
  };

  return (
    <div className="App">
      <h1>Date Calculation</h1>
      <div>
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={handleDateChange} />
        </label>
      </div>
      <div>
        <label>
          Number of Days to Add:
          <input
            type="number"
            value={numberOfDays}
            onChange={handleDaysChange}
          />
        </label>
      </div>
      <div className="bot">
        Result Date: {resultDate ? resultDate.toISOString().split("T")[0] : "-"}
      </div>
    </div>
  );
}

export default App;
